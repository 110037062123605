
import Vue from "vue";
import { Api } from "@/services/api";
import LayoutTopMenu from "@/layouts/LayoutTopMenu.vue";

export default Vue.extend({
  data() {
    return {
      apiRouteName: "/api/accounts/me",
      data: "",
      isBinaryCall: false,
      loading: false
    };
  },
  created(): void {
    this.$emit("update:layout", LayoutTopMenu);
  },
  methods: {
    arraybuffer2string(buf: any) {
      return new Uint8Array(buf).reduce((data, byte) => {
        return data + String.fromCharCode(byte);
      }, "");
    },
    async getApiData() {
      let responseType:
        | "text"
        | "document"
        | "blob"
        | "arraybuffer"
        | "json"
        | "stream"
        | undefined = "json";
      if (this.apiRouteName.indexOf("v2/documents/GetImage") !== -1) {
        responseType = "arraybuffer";
      }
      let response;
      try {
        this.loading = true;
        response = await Api.request().get(this.apiRouteName, {
          responseType
        });
        if (
          typeof responseType !== "undefined" &&
          responseType === "arraybuffer"
        ) {
          this.isBinaryCall = true;
          this.data = this.arraybuffer2string(response.data);
        } else {
          this.isBinaryCall = false;
          this.data = response.data;
        }
        return;
      } catch (err) {
        this.data = err;
      } finally {
        this.loading = false;
      }
    }
  }
});
